import {reverseGeocodeModel} from './reverseGeocode';

export function batchModel(data) {
    if (!(data.batchItems instanceof Array)) {
        return null;
    }

    data.batchItems = data.batchItems.map((batchItem) => {
        if (batchItem.response.error) {
            return { error: batchItem.response.error};
        } else {
            return reverseGeocodeModel(batchItem.response);
        }
    });

    return data;
}
