/**
 * Base class for all the Search services.
 *
 * @private
 * @class Search
 * @module Services
 * @uses ProtocolMixin
 * @uses KeyMixin
 * @uses LimitMixin
 * @uses LanguageMixin
 * @uses BatchMixin
 * @uses TimeZoneMixin
 * @uses TrackingIdMixin
 * @uses RelatedPoisMixin
 *
 * @constructor
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import {clone, addFields} from '../utils';
import {searchModel} from '../../model/search/search';
import {batchModel} from '../../model/search/batchModel';
import searchParams from './searchParameters';
import {modelResponse} from '../../model/modelResponse';
import {
    searchEndpoint,
    batchSearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint
} from 'endpoints';
import {
    serviceFactory,
    singleRequestServiceFactory,
    batchRequestServiceFactory
} from '../../core';

export class Search {
    constructor(options, additionalOptions) {
        this.options = options;
        this.additionalOptions = additionalOptions;
        this.defaultFields = clone(searchParams.create());
        this.fields = {};
    }

    _getRestService() {
        return {
            search: singleRequestServiceFactory(searchEndpoint),
            batch: batchRequestServiceFactory(100, {
                single: batchSearchQueryEndpoint,
                batchSync: batchSyncSearchEndpoint,
                batch: batchSearchEndpoint
            })
        };
    }

    handleBatchServiceCall(data, abortSignal) {
        return this._getRestService().batch(this.fields, data, abortSignal)
            .then(response => modelResponse(response, batchModel));
    }

    handleServiceCall(options, abortSignal) {
        return this._getRestService().search(this.fields, options, abortSignal)
            .then(({ data, trackingId }) => {
                return modelResponse({
                    data: options.bestResult ? data.results[0] : data,
                    trackingId
                }, searchModel);
            });
    }

    construct(serviceName) {
        this.fields = addFields(this.fields, this.defaultFields);
        const service = serviceFactory(
            this.fields,
            SERVICE_TYPES.SEARCH,
            serviceName || 'search',
            this.handleServiceCall.bind(this),
            this.handleBatchServiceCall.bind(this)
        );

        return service(this.options, this.additionalOptions);
    }
}
