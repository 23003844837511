function defaultComparator(first, second) {
    return first === second;
}

export default (firstArray, secondArray, comparator) => {
    if (!comparator) {
        comparator = defaultComparator;
    }

    const result = firstArray.concat(secondArray);
    for (let i = 0; i < firstArray.length; i += 1) {
        for (let j = firstArray.length; j < result.length; j += 1) {
            if (comparator(result[i], result[j])) {
                result.splice(j, 1);
                j -= 1;
            }
        }
    }
    return result;
};

