import {
    point as validatePoint, numberInInterval as validateNumberInInterval
} from '../validators';
import {point as convertPoint} from '../converters';
import {
    budgetInRange, requiredBudget, requiredWithSpecificEngineType, notCommon
} from '../postValidators';
import {fields as commonRoutingParameters} from './commonRoutingParameters';
import {extend, clone} from '../utils';
import parameterApplications from '../../common/parameterApplications';

/**
 * @private
 * @class CalculateReachableRangeParameters
 * @module Services
 */

let fields = {
    __all: {
        validators: [
            requiredBudget,
            notCommon
        ]
    },
    /**
     * Point from which the range calculation should start.
     * The following formats are supported:
     *
     * - **String** (longitude/latitude pair)
     *   - `"4.8,52.3"`
     * - **Array** (longitude/latitude pair)
     *   - `[4.8, 52.3]`
     * - **Object**
     *   - `{lon: 4.8, lat: 52.3}`
     *   - `{lng: 5.8, lat: 53.3}`
     *   - `{x: 53.3, y: 5.8}`
     *   - `{longitude: 5.8, latitude: 53.3}`
     *   - `{lng: Function, lat: Function}` The functions should return a numeric value.
     *
     * @attribute origin
     * @param {String|Array|Object} origin
     */
    origin: {
        validators: [validatePoint],
        converters: [convertPoint],
        application: parameterApplications.PATH
    },

    /**
     * @attribute fuelBudgetInLiters
     * @param {Number} fuelBudgetInLiters Fuel budget in liters that
     * determines the maximal range which can be traveled using the specified
     * Combustion Consumption Model. Required if _vehicleEngineType_ is _combustion_.
     * NOTE: _exactly one_ of `fuelBudgetInLiters`, `energyBudgetInkWh`, `timeBudgetInSec` and
     * `distanceBudgetInMeters` must be set.
     */
    fuelBudgetInLiters: {
        validators: [
            requiredWithSpecificEngineType,
            budgetInRange
        ]
    },

    /**
     * @attribute energyBudgetInkWh
     * @param {Number} energyBudgetInkWh  Electric energy budget in
     * kilowatt hours (kWh) that determines maximal range which can be
     * traveled using the specified Electric Consumption Model.
     * NOTE: _exactly one_ of `fuelBudgetInLiters`, `energyBudgetInkWh`, `timeBudgetInSec` and
     * `distanceBudgetInMeters` must be set.
     */
    energyBudgetInkWh: {
        validators: [
            requiredWithSpecificEngineType,
            budgetInRange
        ]
    },
    /**
     * @attribute distanceBudgetInMeters
     * @param {Number} distanceBudgetInMeters  The distance budget in meters
     * that determines the maximal range which can be traveled.
     * The Consumption Model will only affect the range when routeType is eco.
     * NOTE: _exactly one_ of `fuelBudgetInLiters`, `energyBudgetInkWh`, `timeBudgetInSec` and
     * `distanceBudgetInMeters` must be set.
     */
    distanceBudgetInMeters: {
        validators: [validateNumberInInterval(0, Number.MAX_VALUE)]
    },

    /**
     * @attribute timeBudgetInSec
     * @param {Number} timeBudgetInSec Time budget in seconds that determines maximal
     * range which can be traveled using driving time.
     * The consumption parameters will only affect eco-routes and thereby indirectly the driving time.
     * NOTE: _exactly one_ of `fuelBudgetInLiters`, `energyBudgetInkWh`, `timeBudgetInSec` and
     * `distanceBudgetInMeters` must be set.
     */
    timeBudgetInSec: {
        validators: [validateNumberInInterval(0, Number.MAX_VALUE)]
    }

};

fields = extend(fields, commonRoutingParameters);

export default (fieldsToSkip) => {
    const result = clone(fields);
    if (fieldsToSkip instanceof Array) {
        fieldsToSkip.forEach(function(field) {
            delete result[field];
        });
    }

    return result;
};

