/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/**
 * Makes a placeById request using [Place by Id API](PLACE_BY_ID_URL).
 *
 * The Place by Id service endpoint provides detailed information about the Place found by its identifier (entityId).
 * Currently, Place by Id only supports POI (Points of Interest) ids.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts place by id data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "pizza"
 * }).then(function(response) {
 *      tt.services.placeById({
 *          entityId: response.results[0].id,
 *          key: <Your API key>
 *      }).then(function(placeByIdResult) {
 *          console.log(placeByIdResult);
 *      });
 *   });
 * ```
 *
 * @class placeById
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses LanguageMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import {
    string as validateString, trackingId as validateTrackingId, languageCode as validateLanguageCode
} from '../validators';

import {searchLanguage as convertLanguage} from '../converters';
import { searchModel } from '../../model/search/search';
import parameterApplications from '../../common/parameterApplications';
import { modelResponse } from '../../model/modelResponse';
import { v4 as uuid } from 'uuid';
import { placeByIdEndpoint } from 'endpoints';
import { singleRequestServiceFactory, serviceFactory } from '../../core';

const fields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    /**
     * @attribute entityId
     * @param {String} [options.entityId] The identifier of a specific POI,
     * e.g. `g6Fjo05MRKJpZK81MjgwMDkwMDQyNDY3OTKhdqdVbmlmaWVk`.
     */
    entityId: {
        validators: [validateString],
        required: true
    },
    language: {
        validators: [validateLanguageCode],
        converters: [convertLanguage]
    }
};

export function placeById(options, additionalOptions) {
    const singleRequest = singleRequestServiceFactory(placeByIdEndpoint);

    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, searchModel));
    };

    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'placeById',
        handleServiceCall
    )(options, additionalOptions);
}
