import {isStringSinglePoint} from '../utils/pointUtils';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

/*
 * Class to convert points or arrays of points.
 *
 * By default, the points will be converted to {lon: 00, lat: 00} format.
 *
 * Points in the format [00.0, 00.0] are considered lon-lat points,
 * for lat-lon points set the option isLatLon to true;
 *
 * It is possible to convert the points to a custom format by passing a function in the options
 * as customPointConverter with params longitude and latitude - customPointConverter(longitude,latitude)
 */
export class PointFacade {

    constructor(options = {}) {
        this.options = options;
    }

    convert(data) {
        if (this._isSinglePoint(data)) {
            return this._convertPoint(data);
        }
        return this._convertToArrayOfPoints(data);
    }

    _isSinglePoint(data) {
        if (Array.isArray(data)) {
            if (data.length === 2 && typeof data[0] !== 'object') { // for [00, 00] points
                return true;
            }
            return false;
        }
        if (isString(data)) {
            const points = data.split(':');
            if (points.length >= 2) {
                return false;
            }
        }
        return true;
    }

    _convertToArrayOfPoints(data) {
        if (isString(data)) {
            return data.split(':').map(this._convertPoint.bind(this));
        }
        return data.map(this._convertPoint.bind(this));
    }

    _convertPoint(point) {

        // converts {lat: 00.0, lon: 00.0}
        if (has(point, 'lat') && has(point, 'lon')) {
            return this._covertToDefaultFormat(point.lon, point.lat);
        }

        // converts {latitude: 00.0, longitude: 00.0}
        if (has(point, 'latitude') && has(point, 'longitude')) {
            return this._covertToDefaultFormat(point.longitude, point.latitude);
        }

        // converts {x: 00.0, y: 00.0} x === longitude, y === latitude
        if (has(point, 'x') && has(point, 'y')) {
            return this._covertToDefaultFormat(point.x, point.y);
        }

        // converts [00.0, 00.0]
        if (Array.isArray(point) && point.length === 2) {
            if (this.options.isLatLon) {
                return this._covertToDefaultFormat(point[1], point[0]);
            }
            return this._covertToDefaultFormat(point[0], point[1]);
        }

        // converts "00.000,00.000"
        if (isString(point)) {
            return this._convertStringPoint(point);
        }

        return point;
    }

    _convertStringPoint(point) {
        const p = point.split(',');

        if (!isStringSinglePoint(point) || p.length !== 2) {
            throw new Error('The point is not valid: ' + point);
        }

        if (this.options.isLatLon) {
            return this._covertToDefaultFormat(p[1], p[0]);
        }
        return this._covertToDefaultFormat(p[0], p[1]);
    }

    _covertToDefaultFormat(longitude, latitude) {
        if ((!longitude && longitude !== 0) || (!latitude && latitude !== 0)) {
            throw new Error('Longitude and latitude must be provided.');
        }
        if (isFunction(this.options.customPointConverter)) {
            return this.options.customPointConverter(longitude, latitude);
        }
        return {
            lng: parseFloat(longitude),
            lat: parseFloat(latitude)
        };
    }
}
