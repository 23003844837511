/* eslint-disable max-len */
/**
 * Reverse geocode service implementation.
 *
 * It converts geographical coordinates into a textual address representation using the TomTom
 * [Search API - Reverse Geocode](REVERSE_GEOCODE_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 *
 * The final response from the service is a JSON object with the following structure:
 *
 * ```js
 * {
 *   address: {
 *     buildingNumber: 19,
 *     streetNumber: 19,
 *     landmark: '',
 *     street: 'N 2nd St',
 *     streetName: 'N 2nd St',
 *     streetNameAndNumber: '',
 *     speedLimit: '',
 *     countryCode: 'U',
 *     countrySubdivision: 'CA',
 *     countrySecondarySubdivision: 'Santa Clara',
 *     countryTertiarySubdivision: '',
 *     municipality: 'San Jose',
 *     postalCode: '95113',
 *     municipalitySubdivision: 'San Francisco Bay Area',
 *     freeformAddress: '19 N 2nd St, San Jose, CA, 95113'
 *   },
 *    position: {
 *     lng: -121.8898',
 *     lat: '37.337083
 *   },
 *   roadUse: ['LocalStreet']
 * }
 * ```
 *
 * The response is also extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(response) {
 *   console.log(response);
 * }
 * tt.services.reverseGeocode({
 *   key: <Your API key>,
 *   position: '19.449: 51.759'
 * }).then(callbackFn);
 * ```
 *
 * @class reverseGeocode
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses ProtocolMixin
 * @uses RadiusMixin
 * @uses PositionMixin
 * @uses HeadingMixin
 * @uses LanguageMixin
 * @uses ViewMixin
 * @uses ReturnMatchTypeMixin
 * @uses AllowFreeformNewlineMixin
 * @uses BatchMixin
 * @uses EntityTypeMixin
 * @uses MapcodesMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 *
 * @constructor
 * @param {Object} [options] Options to be passed to the reverseGeocode call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */
/* eslint-enable */

/**
 * Allows, if possible, the receiving of speed limit information at the given location.
 *
 * @attribute returnSpeedLimit
 * @param {Boolean} [options.returnSpeedLimit] Enable or disable the feature.
 */

/**
 * If a street number is sent in along with the request, the response may
 * include the side of the street (Left/Right), and also an offset position for that street number.
 *
 * @attribute number
 * @param {String} [options.number] Street number as a string.
 */

/**
 * Requires including a road use array for reversegeocodes at street level.
 *
 * @attribute returnRoadUse
 * @param {Boolean} [options.returnRoadUse] Enable or disable the feature.
 */

/**
 * Use this option if you want to restrict the result to one, or a group of the following road uses:
 * - `"LimitedAccess"`
 * - `"Arterial"`
 * - `"Terminal"`
 * - `"Ramp"`
 * - `"Rotary"`
 * - `"LocalStreet"`
 *
 * @attribute roadUse
 * @param {String[]|String} [options.roadUse] An array of strings, or just one string with comma-separated values.
 */

/**
 * Represents the street's number for the structured address.
 *
 * @attribute streetNumber
 * @param {String} [options.streetNumber] The street's number.
 */

/**
 * * It allows the service to decide whether the Daylight Saving Time time zone should be used or not.
 * * It must conform to RFC 3339.
 * @attribute dateTime
 * @param {String} [options.dateTime] The date and time used to return time zone information.
 */

import {reverseGeocodeBase} from './reverseGeocodeBase';
import {reverseGeocodeParams} from './reverseGeocodeParameters';
import {reverseGeocodeType} from './types';

export function reverseGeocode(options, additionalOptions) {
    const ReverseGeocodeBasePrototype = reverseGeocodeBase.prototype.constructor;

    function ReverseGeocode(options, additionalOptions) {
        ReverseGeocodeBasePrototype.call(this, options, additionalOptions);
        this.fields = reverseGeocodeParams(reverseGeocodeType);
    }

    ReverseGeocode.prototype = new ReverseGeocodeBasePrototype();
    ReverseGeocode.prototype.constructor = ReverseGeocode;
    return (new ReverseGeocode(options, additionalOptions)).construct('reverseGeocode');
}
