import { createRequestParams } from '../request/requestParamsFactory';
import { batchRequestStrategy } from './batchRequestStrategy';

const mainPostParamsName = ['origins', 'destinations'];

function getOptionalBodyParams(postParams) {
    const optionalBodyParamsNames = Object.keys(postParams)
        .filter(paramName => mainPostParamsName.indexOf(paramName) === -1);

    if (!optionalBodyParamsNames.length) {
        return null;
    }

    return optionalBodyParamsNames.reduce((accumulator, paramName) => {
        accumulator[paramName] = postParams[paramName];
        return accumulator;
    }, {});
}

function getBodyParams(postParams) {
    const bodyParams = {
            origins: postParams.origins,
            destinations: postParams.destinations
        },
        optionalBodyParams = getOptionalBodyParams(postParams);

    if (optionalBodyParams) {
        bodyParams.options = {
            post: optionalBodyParams
        };
    }

    return bodyParams;
}

export function matrixFactory(maxNoOfElementsInBatchRequest, matrixEndpoints) {
    return (fields, options, abortSignal) => {
        const requestParams = createRequestParams(fields, options);
        const bodyParams = getBodyParams(requestParams.postParams);
        const queryParams = requestParams.queryParams;
        const headers = requestParams.headerParams;

        const batchMode = options.batchMode ||
            (bodyParams.origins.length * bodyParams.destinations.length <= maxNoOfElementsInBatchRequest ?
                'sync' : 'redirect');

        return batchRequestStrategy({
            batchMode,
            headers,
            waitTimeSeconds: options.waitTimeSeconds,
            queryParams: queryParams,
            bodyParams: bodyParams,
            endpoints: {
                sync: matrixEndpoints.sync,
                async: matrixEndpoints.async
            },
            abortSignal
        });
    };
}
