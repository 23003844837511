import {SERVICE_TYPES} from 'Core/serviceTypes';
import {
    string as validateString, trackingId as validateTrackingId, naturalInteger as validateNaturalInteger,
    point as validatePoint, countrySet as validateCountrySet, languageCode as validateLanguageCode,
    clientTime as validateClientTime, limit as validateLimit
} from '../validators';
import {searchLanguage as convertLanguage, point as convertPoint} from '../../services/converters';
import parameterApplications from '../../common/parameterApplications';
import {modelResponse} from '../../model/modelResponse';
import { v4 as uuid } from 'uuid';
import { autocompleteEndpoint } from 'endpoints';
import { singleRequestServiceFactory, serviceFactory } from '../../core';
/**
* The Autocomplete API enables you to make a more meaningful Search API call
* by recognizing entities inside an input query and offering them as query terms.
*
* Makes a search request using the TomTom [Search API - Autocomplete](AUTOCOMPLETE_URL).
*
* ### Response
* The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
* associated with the request.
*
* Please refer to {{#crossLinkModule "Services"}}Difference between
* API responses and this library's responses{{/crossLinkModule}} section.
*
* @class autocomplete
* @module Services
* @namespace Services.services
* @uses KeyMixin
* @uses QueryMixin
* @uses LimitMixin
* @uses CountrySetMixin
* @uses RadiusMixin
* @uses CenterMixin
* @uses TrackingIdMixin
* @uses AbortSignalMixin
* @constructor
*
* @param {Object} [options]
* @param {Object} [additionalOptions] Additional options to be passed to the service.
*/
const fields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    query: {
        validators: [validateString],
        required: true,
        application: parameterApplications.PATH
    },
    /**
     * The value should correspond to one of the supported IETF language codes.
     * The list is available [here](SEARCH_API_SUPPORTED_LANGS_URL).
     *
     * The code is case insensitive.
     *
     * @attribute language
     * @param {String} options.language Language code that decides in which language the search results
     * should be returned.
     */
    language: {
        validators: [validateLanguageCode],
        converters: [convertLanguage],
        required: true
    },
    limit: {
        validators: [validateLimit]
    },
    countrySet: {
        validators: [validateCountrySet]
    },
    radius: {
        validators: [validateNaturalInteger]
    },
    /**
     * A result is only included if at least one segment is of any of the indicated types.
     *   Value: A comma-separated list that consists of the types of segments.
     *   Usage examples:
     *   * resultSet=category
     *   * resultSet=brand
     *   * resultSet=category,brand
     * @attribute resultSet
     * @param {String} [options.resultSet] Restricts the result space based on their segment types.
     */
    resultSet: {
        validators: [validateString]
    },
    center: {
        converters: [convertPoint],
        validators: [validatePoint],
        cast: (center, requestOptions) => {
            const coordinates = center.split(',');
            requestOptions.lat = coordinates[0];
            requestOptions.lon = coordinates[1];
        }
    },
    sessionId: {
        validators: [validateString]
    },
    clientTime: {
        validators: [validateClientTime]
    }
};

export function autocomplete(options, additionalOptions) {
    const singleRequest = singleRequestServiceFactory(autocompleteEndpoint);

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response));
    }

    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'autocomplete',
        handleServiceCall
    )(options, additionalOptions);
}
