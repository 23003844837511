import reachableRangeModel from './calculateReachableRange';

export default (data) => {
    if (!Array.isArray(data.batchItems)) {
        return null;
    }
    data.batchItems = data.batchItems.map((batchItem) => {
        if (batchItem.response.error) {
            return { error: batchItem.response.error};
        } else {
            return reachableRangeModel(batchItem.response);
        }
    });

    return data;
};
