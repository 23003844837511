import mainConfig from 'mainConfig';
import { setProductInfo } from './requester/analytics';

import * as searchService from './search';
import * as routingService from './routing';
import * as trafficService from './traffic';
import * as mapDisplayService from './mapDisplay';

const sdkInfo = {
    version: mainConfig['sdk.version']
};

const services = {
    ...searchService,
    ...routingService,
    ...trafficService,
    ...mapDisplayService
};

/**
 * <h3 id="overview">Overview</h3>
 * The Services library provides the necessary tools to integrate all Tomtom services with your application.
 * We take care of all the aspects required to make the calls to the Tomtom APIs, such as the construction of the URL,
 * validation, and error handling. It is worth noting that by using our help, you avoid making mistakes and extra calls
 * to the service.
 *
 * We provide three different bundles of the library:
 *
 * - __services-web.min.js__ - use this file if you want to include the library directly on a web page,
 * it can be imported in the `<head>` of your HTML document.
 * The `tt` global object  will be created and attached to the `window`.
 * - __services.min.js__ - use this file if you are using a module bundler. It conforms the
 * <a target="_blank" rel=”noopener” href="https://github.com/umdjs/umd">UMD standard</a>.
 * In case you use ES6 imports all you need is: <br/>`import tt from 'services.min.js'`
 * - __services-node.min.js__ - use this file if you want to use our services in Node.js.
 *
 * <div class="alert alert-info margin-top-16">
 * If you want to display a map on the webpage, go to
 * {{#crossLinkModule "Maps"}}Maps library documentation{{/crossLinkModule}}.
 *  </div>
 *
 * <h3 id="difference_between_api_responses_and_this_librarys_responses">Difference
 * between API responses and this library's responses</h3>
 * _Services library_ provides an easy way to get responses from APIs like
 * <a href="https://developer.tomtom.com/search-api/search-api-documentation"
 * target="_blank" rel=”noopener”>Search API</a>,
 * <a href="https://developer.tomtom.com/routing-api/routing-api-documentation"
 * target="_blank" rel=”noopener”>Routing API</a>
 * or <a href="https://developer.tomtom.com/traffic-api/traffic-api-documentation"
 * target="_blank" rel=”noopener”>Traffic API</a>.
 * In order to maintain consistency between data types returned by those APIs, and
 * to make it more convenient to use those responses and data types with the
 * {{#crossLinkModule "Maps"}}Maps library{{/crossLinkModule}}, **_Services library_ alters
 * original responses**:
 * * point representations like `[lat, lon]`, `'lat, lon'`, `{lon, lat}`, `{latitude, longitude}`, `{x, y}`
 * are mapped to `{lng, lat}` Object type.
 * * some services responses are extended by additional methods like `toGeoJson()`
 * ({{#crossLink "Services.services.calculateRoute"}}calculateRoute{{/crossLink}},
 * {{#crossLink "Services.services.calculateReachableRange"}}calculateReachableRange{{/crossLink}},
 * {{#crossLink "Services.services.incidentDetails"}}incidentDetails{{/crossLink}},
 * {{#crossLink "Services.services.alongRouteSearch"}}alongRouteSearch{{/crossLink}},
 * {{#crossLink "Services.services.placeById"}}placeById{{/crossLink}},
 * {{#crossLink "Services.services.fuzzySearch"}}fuzzySearch{{/crossLink}},
 * {{#crossLink "Services.services.geometrySearch"}}geometrySearch{{/crossLink}},
 * {{#crossLink "Services.services.nearbySearch"}}nearbySearch{{/crossLink}},
 * {{#crossLink "Services.services.categorySearch"}}categorySearch{{/crossLink}},
 * {{#crossLink "Services.services.poiSearch"}}poiSearch{{/crossLink}},
 * {{#crossLink "Services.services.structuredGeocode"}}structuredGeocode{{/crossLink}},
 * {{#crossLink "Services.services.geocode"}}geocode{{/crossLink}}).

 * Usage of this method is presented on the documentation page of each service mentioned.
 * * The `getTrackingId()` method is also available as part of the service response
 * in order to obtain the `Tracking-ID` associated with the request.
 *
 * Most services are asynchronous and return
 * <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
 * target="_blank" rel=”noopener”>Promise</a>
 * except for {{#crossLink "Services.services.staticImage"}}staticImage{{/crossLink}} and
 * {{#crossLink "Services.services.poiPhotos"}}poiPhotos{{/crossLink}}, which return URL string.
 *
 * @module Services
 * @main Services
 */

/**
 * This object aggregates all the services available in TomTom.
 *
 * Please refer to the following documentation to check what properties are on the tt object,
 * and how you can make calls to TomTom services.
 *
 * @class tt
 * @namespace Services
 */

/**
 * @property services
 * @type services
 * @description An object, which contains all supported services.
 */

/**
 * @method sdkInfo
 * @type function
 * @description Returns SDK details (e.g., version).
 * @return {Object}
 */

/**
 * @method setProductInfo
 * @type function
 * @description This method sets the content of the TomTom-User-Agent header. We are collecting anonymous data for
 * statistics of the usage of our services in order to increase the quality of our products.
 * @param {String} productId Identifier (e.g., a name) of your application e.g.,&nbsp;MyApplication
 * @param {String} productVersion Version of your application e.g.,&nbsp;1.0.2
 * @example
 * ```javascript
 * tt.setProductInfo('your-product-id', 'your-product-version');
 * ```
 */

export default {
    sdkInfo,
    setProductInfo,
    services
};
