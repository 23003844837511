/* eslint-disable max-len */
/**
 * The Long Distance EV Routing service calculates a route between a given origin and
 * destination. The route contains charging stops that have been added automatically
 * based on the vehicle's consumption and charging model.
 *
 * * Each charging stop results in an additional leg in the route response.
 * * Each additional leg contains a `chargingInformationAtEndOfLeg` element in its leg summary.
 * * Both the route summary and the leg summary include a `remainingChargeAtArrivalInkWh` element.
 * * The route summary includes a `totalChargingTimeInSeconds` element.
 *
 * The `chargingInformationAtEndOfLeg` field consists of:
 *
 * * target battery charge
 * * charging time
 * * details about the charging stop
 *
 *  ### The types used in the Long Distance EV Routing service:
 *
 *  - **chargingMode** - An object that contains `chargingConnections` and a `chargingCurve` that describes the
    charging behavior of the vehicle using the specified charging connections.
 *
 *  - **chargingConnections** - A non-empty array of at most 20 unique `chargingConnections` which
 *  result in the same charging curve.
 *
 *  - **chargingConnection** - Contains one `facilityType` and one `plugType` which are compatible
 *  with the vehicle.
 *
 *      ```js
 *      {
 *          "facilityType" : "Charge_380_to_480V_3_Phase_at_32A",
 *          "plugType" : "IEC_62196_Type_2_Outlet"
 *      }
 *      ```
 *
 *  - **facilityType** - A string matching a `facilityType` in the values table, which
 *  can be found in <a href="https://developer.tomtom.com/routing-api/routing-api-documentation/long-distance-ev-routingLong">
 *  Distance EV Routing API documentation</a>.
 *
 *  - **plugType** - A string matching a `plugType` in the values table, which
 * can be found in <a href="https://developer.tomtom.com/routing-api/routing-api-documentation/long-distance-ev-routingLong">
 * Distance EV Routing API documentation</a>.
 *
 *  - **chargingCurve** - A piecewise-linear function which maps a target charge level to
 *  the time it takes to charge an empty battery to this level, that is used to compute charging times of
 * the vehicle. It has to follow these rules:
 *      1. It is given by a sequence of at most 10 non-duplicate `chargingCurveSupportPoints` that are totally,
 *      increasingly ordered in `timeToChargeInSeconds` and `chargeInkWh`.
 *      2. The last `chargeInkWh` is `maxChargeInkWh`.
 *      3. The curve is given by connecting the given points in order after adding the first point:
 *
 *
 *  - **chargingCurveSupportPoint** - A supporting point of a charging curve consisting
 * of `chargeInkWh` and `timeToChargeInSeconds` such that the time it takes to charge the battery from
 * `0` kWh to `chargeInkWh` kWh is given by `timeToChargeInSeconds`.
 *
 *      ```js
 *      {
 *          "chargeInkWh" : 6,
 *          "timeToChargeInSeconds" : 300
 *      }
 *      ```
 *
 * - **timeToChargeInSeconds** - A time span in seconds. A non-negative integer.
 *
 * - **chargeInkWh** - A battery charge level in kWh. A float in the range (0, `maxChargeInkWh`>.
 *
 * ### Response
 * This service extends the API response by providing the following methods:
 * * `toGeoJson()`, converts route data into
 * a Feature Collection of <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.4">LineString</a>
 * or <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.5">MultiLineString</a> geometries.
 * * `getTrackingId()`, returns the `Tracking-ID` associated with the request.
 *
 * To read more about services responses take a look at {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class longDistanceEVRouting
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses CommonRoutingParameters
 * @uses LongDistanceEVRoutingParameters
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 * @param {Object|Array} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```js
 * tt.services.longDistanceEVRouting({
 *   key: <Your API key>,
 *   locations: '4.878,52.367:4.879,52.367',
 *   vehicleEngineType: 'electric',
 *   avoid: 'unpavedRoads',
 *   constantSpeedConsumptionInkWhPerHundredkm: ['50.0,6.5', '100.0,8.5'],
 *   currentChargeInkWh: 10,
 *   maxChargeInkWh: 40,
 *   minChargeAtDestinationInkWh: 5.2,
 *   minChargeAtChargingStopsInkWh: 1.5,
 *   chargingModes: [
 *       {
 *           chargingConnections: [
 *               {
 *                   facilityType: 'Charge_380_to_480V_3_Phase_at_32A',
 *                   plugType: 'IEC_62196_Type_2_Outlet'
 *               }
 *           ],
 *           chargingCurve: [
 *               {
 *                   chargeInkWh: 6,
 *                   timeToChargeInSeconds: 300
 *               },
 *               {
 *                   chargeInkWh: 12,
 *                   timeToChargeInSeconds: 720
 *               }
 *           ]
 *       }
 *   ]
 * }).then(function(routeData) {
 *     console.log(routeData.toGeoJson());
 *   });
 * ```
 */
/* eslint-enable max-len */
/* jshint nomen:false */
import { SERVICE_TYPES } from 'Core/serviceTypes';
import {getLongDistanceEVRoutingParameters} from './longDistanceEVRoutingParameters';
import {calculateRouteModel} from '../../model/routing/calculateRoute';
import {modelResponse} from '../../model/modelResponse';
import { longDistanceEVRoutingEndpoint } from 'endpoints';
import {
    singleRequestServiceFactory,
    serviceFactory
} from '../../core';

export function longDistanceEVRouting(options, additionalOptions) {
    const fields = getLongDistanceEVRoutingParameters();
    const singleRequest = singleRequestServiceFactory(longDistanceEVRoutingEndpoint);
    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, calculateRouteModel));
    };
    return serviceFactory(
        fields,
        SERVICE_TYPES.ROUTING,
        'longDistanceEVRouting',
        handleServiceCall
    )(options, additionalOptions);
}
