/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
import template from './template';

function createKeyValueString(key, value) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function mapQueryParamToKeyValueString(key, queryParams) {
    const queryParamValue = queryParams[key];
    let result = '';
    if (Array.isArray(queryParamValue)) {
        result = queryParamValue.map(function(value) {
            return createKeyValueString(key, value);
        }).join('&');
    } else {
        result = createKeyValueString(key, queryParams[key]);
    }
    return result;
}

function withQueryParams(url, queryParams) {
    if (!queryParams) {
        return url;
    }

    const queryString = Object.keys(queryParams)
        .map((key) => {
            return mapQueryParamToKeyValueString(key, queryParams);
        })
        .join('&');
    return url + '?' + queryString;
}

export default (urlTemplate, pathParams, queryParams) => {
    const url = template(urlTemplate, pathParams);
    return withQueryParams(url, queryParams);
};
