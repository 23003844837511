/* eslint-disable max-len */
/**
 * Allows requests to be made using the TomTom's
 * [Search API - Geocode](GEOCODE_SERVICE_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts geocode data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 * tt.services.geocode({
 *   key: <Your API Key>,
 *   query: 'Amsterdam, The Netherlands'
 * }).then(callbackFn);
 * ```
 *
 * @class geocode
 * @namespace Services.services
 * @module Services
 * @uses BatchMixin
 * @uses KeyMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses LimitMixin
 * @uses OffsetMixin
 * @uses LanguageMixin
 * @uses BoundingBoxMixin
 * @uses CenterMixin
 * @uses CountrySetMixin
 * @uses RadiusMixin
 * @uses BestResultMixin
 * @uses ProtocolMixin
 * @uses ViewMixin
 * @uses ExtendedPostalCodesForMixin
 * @uses TimeZoneMixin
 * @uses MapcodesMixin
 * @uses TrackingIdMixin
 * @uses EntityTypeSetMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the geocode call,
 *     or an array of such option objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import {geocodeModel} from 'model/geocode/geocode';
import {batchModel} from 'model/geocode/batchGeocode';
import geocodeParams from './geocodeParameters';
import { modelResponse } from '../../model/modelResponse';

import {
    batchSearchQueryEndpoint,
    batchSyncSearchEndpoint,
    batchSearchEndpoint,
    geocodeEndpoint
} from 'endpoints';
import {
    singleRequestServiceFactory, batchRequestServiceFactory, serviceFactory
} from '../../core';

const fields = geocodeParams.create();

export function geocode(options, additionalOptions) {
    const singleRequest = singleRequestServiceFactory(geocodeEndpoint);
    const batchRequest = batchRequestServiceFactory(100, {
        single: batchSearchQueryEndpoint,
        batchSync: batchSyncSearchEndpoint,
        batch: batchSearchEndpoint
    });

    function handleServiceCall(requestOptions, abortSignal) {
        return singleRequest(fields, requestOptions, abortSignal)
            .then(({ data, trackingId }) => {
                return modelResponse({
                    data: requestOptions.bestResult ? data.results[0] : data,
                    trackingId
                }, geocodeModel);
            });
    }
    function handleBatchServiceCall(data, abortSignal) {
        return batchRequest(fields, data, abortSignal).then(results => modelResponse(results, batchModel));
    }
    return serviceFactory(
        fields, SERVICE_TYPES.SEARCH,
        'geocode',
        handleServiceCall,
        handleBatchServiceCall
    )(options, additionalOptions);
}
