export default {
    or: (value, defaultValue) => {
        return value || defaultValue;
    },

    required: (value, error) => {
        if (value === undefined || value === null) {
            throw new TypeError(error);
        }
        return value;
    }
};
