/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Points of Interest Search](POINTS_INTEREST_SEARCH_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts points of interests search data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *    console.log(result);
 * };
 *
 * tt.services.poiSearch(({
 *   key: <Your API key>,
 *   query: 'pizza'
 * }).then(callbackFn);
 * ```
 *
 * @class poiSearch
 * @namespace Services.services
 * @extends Search
 * @uses ExtendedPostalCodesForMixin
 * @uses CenterMixin
 * @uses BoundingBoxMixin
 * @uses CountrySetMixin
 * @uses OffsetMixin
 * @uses BestResultMixin
 * @uses QueryMixin
 * @uses TypeaheadMixin
 * @uses CategorySetMixin
 * @uses ViewMixin
 * @uses BrandSetMixin
 * @uses ConnectorSetMixin
 * @uses OpeningHoursMixin
 * @uses MapcodesMixin
 * @uses FuelSetMixin
 * @uses MinPowerKwMixin
 * @uses MaxPowerKwMixin
 * @uses AbortSignalMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

/**
 * This option specifies the search radius in meters using the coordinates given to the `center`
 * option as origin.
 *
 * @attribute radius
 * @param {Number} [options.radius=0] A positive integer value in meters.
 */

/* eslint-enable */
import { Search } from './search';
import { boundingBox } from './searchParameters';
import { POI_SEARCH } from '../../common/searchTypes';
class PoiSearch extends Search {
    constructor(options, additionalOptions) {
        super(options, additionalOptions);

        this.fields.type = {
            defaultValue: POI_SEARCH,
            visible: false
        };

        this.fields.boundingBox = boundingBox();
    }
}

export function poiSearch(options, additionalOptions) {
    return (new PoiSearch(options, additionalOptions)).construct('poiSearch');
}
