import { convertResponsePoints, toGeoJson } from '../modelUtils';

export function geocodeModel(data) {
    if (!data) {
        return data;
    }
    // converts location points
    if (Array.isArray(data.results)) {
        data.results.forEach(result => {
            if (Array.isArray(result)) {
                result.forEach(r => {
                    convertResponsePoints(r);
                });
            } else {
                convertResponsePoints(result);
            }
        });
    }

    convertResponsePoints(data);

    return Object.assign(data, {
        toGeoJson: () => toGeoJson(data)
    });
}
