/**
 * Provides the current traffic model ID which is the traffic model number for the viewport needed for the Traffic API -
 * Incidents Layer, and the {{#crossLink "IncidentDetails"}}Traffic API - Incident Details{{/crossLink}}
 * calls.
 *
 * It is updated every minute, and is valid for two minutes before it times out.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class incidentViewport
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses ProtocolMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 * @example
 * ```js
 * function callbackFn(response) {
 *   console.log(response);
 * }
 * tt.services.incidentViewport({
 *   key: <Your API key>
 * }).then(callbackFn);
 * ```
 */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import parameterApplications from 'common/parameterApplications';
import { v4 as uuid } from 'uuid';
import { modelResponse } from '../model/modelResponse';
import { incidentViewportEndpoint } from '../endpoints/endpoints';
import {singleRequestServiceFactory, serviceFactory } from '../core';
import {
    key as validateKey, trackingId as validateTrackingId, oneOfValue as validateOneOfValue,
    string as validateString
} from './validators';

const fields = {
    key: {
        validators: [validateKey]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    protocol: {
        validators: [validateOneOfValue(['http', 'https'], 'protocol')]
    },
    sessionId: {
        validators: [validateString]
    }
};

export function incidentViewport(options, additionalOptions) {
    const singleRequest = singleRequestServiceFactory(incidentViewportEndpoint);

    function handleServiceCall(data, abortSignal) {
        return singleRequest(fields, data, abortSignal).then(modelResponse);
    }

    return serviceFactory(
        fields,
        SERVICE_TYPES.TRAFFIC_INCIDENTS,
        'incidentViewport',
        handleServiceCall
    )(options, additionalOptions);
}
