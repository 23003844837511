import {PointFacade} from '../pointFacade';
import {getRouteFeature} from './helpers/getRouteFeature';
import {newFeatureCollection} from '../modelUtils';

const pointFacade = new PointFacade();
const newLineString = () => {
    return {
        type: 'Feature',
        geometry: {
            type: 'LineString'
        },
        properties: {}
    };
};

const setProperty = (properties, propertyName, value) => {
    if (value) {
        properties[propertyName] = value;
    }
};

const toGeoJson = (data) => {
    const result = newFeatureCollection();
    if (!data || !data.routes) {
        return result;
    }
    for (let i = 0; i < data.routes.length; i += 1) {
        result.features.push(getRouteFeature(data.routes[i]));
    }
    return result;
};

const toRouteSectionsCollection = (data) => {
    const results = [];

    if (!data || !data.routes) {
        return results;
    }

    data.routes.forEach(route => {
        const points = [];

        for (let i = 0; i < route.legs.length; i += 1) {
            points.push(...route.legs[i].points);
        }

        const featureCollection = newFeatureCollection();
        route.sections.forEach(section => {
            let coordinates = points.slice(section.startPointIndex, section.endPointIndex + 1);
            coordinates = coordinates.map(lngLat => [lngLat.lng, lngLat.lat]);

            const lineString = newLineString();

            lineString.geometry.coordinates = coordinates;

            setProperty(lineString.properties, 'sectionType', section.sectionType);
            setProperty(lineString.properties, 'travelMode', section.travelMode);
            setProperty(lineString.properties, 'countryCode', section.countryCode);
            setProperty(lineString.properties, 'simpleCategory', section.simpleCategory);
            setProperty(lineString.properties, 'effectiveSpeedInKmh', section.effectiveSpeedInKmh);
            setProperty(lineString.properties, 'delayInSeconds', section.delayInSeconds);
            setProperty(lineString.properties, 'magnitudeOfDelay', section.delayInSeconds);
            setProperty(lineString.properties, 'tec', section.tec);

            featureCollection.features.push(lineString);
        });

        results.push(featureCollection);
    });

    return results;
};

export function calculateRouteModel(data) {
    const routes = data.routes;

    // convert points
    if (routes && routes.length) {
        routes.forEach(route => {
            route.legs.forEach(leg => {
                leg.points = pointFacade.convert(leg.points);
            });
        });
    }

    return Object.assign(data, {
        toGeoJson: () => toGeoJson(data),
        toRouteSectionsCollection: () => toRouteSectionsCollection(data)
    });
}
