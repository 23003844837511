import { convertResponsePoints, toGeoJson } from '../modelUtils';

function convertEntryPoints(element) {
    if (element && element.entryPoints && Array.isArray(element.entryPoints)) {
        element.entryPoints.forEach(entryPoint => {
            convertResponsePoints(entryPoint);
        });
    }
}

export function searchModel(data) {
    if (!data) {
        return data;
    }
    // converts location points
    if (Array.isArray(data.results)) {
        data.results.forEach(result => {
            if (Array.isArray(result)) {
                result.forEach(r => {
                    convertResponsePoints(r);
                    convertEntryPoints(r);
                });
            } else {
                convertResponsePoints(result);
                convertEntryPoints(result);
            }
        });
    } else {
        convertResponsePoints(data);
        convertEntryPoints(data);
    }

    return Object.assign(data, {
        toGeoJson: () => toGeoJson(data)
    });
}
