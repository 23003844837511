export function flowSegmentDataModel(data) {
    const coordinates = data.flowSegmentData.coordinates.coordinate.map(coordinate => {
        return {
            lat: coordinate.latitude,
            lng: coordinate.longitude
        };
    });

    return Object.assign(data, { flowSegmentData: {...data.flowSegmentData, coordinates: {coordinate: coordinates} }});
}
