import {
    bool as validateBool, string as validateString, trackingId as validateTrackingId, oneOfValue as validateOneOfValue,
    naturalInteger as validateNaturalInteger, languageCode as validateLanguageCode, point as validatePoint,
    entityType as validateEntityType, number as validateNumber, roadUse as validateRoadUse,
    mapcodes as validateMapcodes, dateTime as validateDateTime, limit as validateLimit
} from '../validators';
import {
    roadUse as convertRoadUse, array as convertArray, point as convertPoint,
    commaSeparated as convertCommaSeparated
} from '../converters';
import {reverseGeocodeType, crossStreetLookupType} from './types';
import parameterApplications from '../../common/parameterApplications';
import { v4 as uuid } from 'uuid';

const geocodeBaseFields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    language: {
        validators: [validateLanguageCode]
    },
    position: {
        converters: [convertPoint],
        validators: [validatePoint],
        required: true,
        application: parameterApplications.PATH
    },
    heading: {
        validators: [validateNumber]
    },
    radius: {
        validators: [validateNaturalInteger]
    },
    protocol: {
        validators: [validateOneOfValue(['http', 'https'], 'protocol')]
    },
    view: {
        validators: [validateOneOfValue(['AR', 'IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'CN'], 'view')]
    },
    allowFreeformNewline: {
        validators: [validateBool]
    }
};

const additionalGeocodeFields = {
    type: {
        defaultValue: reverseGeocodeType,
        visible: false,
        application: parameterApplications.PATH
    },

    entityType: {
        converters: [convertArray, convertCommaSeparated],
        validators: [validateEntityType]
    },

    returnSpeedLimit: {
        validators: [validateBool]
    },

    number: {
        validators: [validateString]
    },

    returnRoadUse: {
        validators: [validateBool]
    },

    roadUse: {
        converters: [convertRoadUse],
        validators: [validateRoadUse]
    },

    streetNumber: {
        validators: [validateString]
    },

    returnMatchType: {
        validators: [validateBool]
    },

    mapcodes: {
        validators: [validateMapcodes]
    },

    dateTime: {
        validators: [validateDateTime]
    }
};

const crossStreetLookupFields = {
    type: {
        defaultValue: crossStreetLookupType,
        visible: false,
        application: parameterApplications.PATH
    },

    limit: {
        validators: [validateLimit]
    }
};

export function reverseGeocodeParams(type) {
    if (type === reverseGeocodeType) {
        return Object.assign({}, geocodeBaseFields, additionalGeocodeFields);
    } else if (type === crossStreetLookupType) {
        return Object.assign({}, geocodeBaseFields, crossStreetLookupFields);
    }
    throw new Error('Unsupported geocode type: ' + type);
}
