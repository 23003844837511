import serviceModule from './index';

const factory = () => {
    return {
        sdkInfo: serviceModule.sdkInfo,
        setProductInfo: serviceModule.setProductInfo,
        services: serviceModule.services
    };
};

window.tt = window.tt ? {...window.tt, ...factory()} : factory();
