import {PointFacade} from '../pointFacade';

const pointFacade = new PointFacade({
    isLatLon: true
});

const convertPoints = elem => {
    if (elem.position) {
        elem.position = pointFacade.convert(elem.position);
    }

    if (elem.address && elem.address.boundingBox) {
        elem.address.boundingBox.northEast = pointFacade.convert(elem.address.boundingBox.northEast);
        elem.address.boundingBox.southWest = pointFacade.convert(elem.address.boundingBox.southWest);
    }
};

export function reverseGeocodeModel(json) {
    // convert points
    json.addresses.forEach(result => {
        if (Array.isArray(result)) {
            result.forEach(r => {
                convertPoints(r);
            });
        }
        convertPoints(result);
    });
    return json;
}
