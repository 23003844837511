import {PointFacade} from './pointFacade';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

const pointFacade = new PointFacade();

export const convertResponsePoints = (elem) => {
    if (!elem) {
        return;
    }

    if (elem.boundingBox) {
        elem.boundingBox.btmRightPoint = pointFacade.convert(elem.boundingBox.btmRightPoint);
        elem.boundingBox.topLeftPoint = pointFacade.convert(elem.boundingBox.topLeftPoint);
    }
    if (elem.viewport) {
        elem.viewport.btmRightPoint = pointFacade.convert(elem.viewport.btmRightPoint);
        elem.viewport.topLeftPoint = pointFacade.convert(elem.viewport.topLeftPoint);
    }
    if (elem.position) {
        elem.position = pointFacade.convert(elem.position);
    }
    if (elem.summary && elem.summary.geoBias) {
        elem.summary.geoBias = pointFacade.convert(elem.summary.geoBias);
    }
};

export const setProperty = (properties, propertyName, value) => {
    if (!isNil(value)) {
        properties[propertyName] = value;
    }
};

export const newFeature = (properties, type) => {
    return {
        type: 'Feature',
        geometry: {type, coordinates: []},
        properties: properties
    };
};

export const newFeatureCollection = () => {
    return {
        type: 'FeatureCollection',
        features: []
    };
};

export const getFeature = (data) => {
    const properties = {};
    properties.id = data.id;

    if (isObject(data)) {
        Object.keys(data).forEach(name => {
            setProperty(properties, name, data[name]);
        });
    }

    const feature = newFeature(properties, 'Point');
    feature.geometry.coordinates = [data.position.lng, data.position.lat];

    return feature;
};

export const toGeoJson = (data) => {
    const result = newFeatureCollection();

    for (let i = 0; i < data.results.length; i++) {
        const feature = getFeature(data.results[i]);
        result.features.push(feature);
    }
    return result;
};
