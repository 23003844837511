import {
    string as validateString, trackingId as validateTrackingId, zoomLevel as validateZoomLevel,
    validateBoundingBox, oneOfValue as validateOneOfValue, integer as validateInteger
} from '../validators';

import {convertBoundingBox, integer as convertInteger} from '../converters';
import parameterApplications from '../../common/parameterApplications';
import { v4 as uuid } from 'uuid';

export default {
    create: () => {
        return {
            key: {
                validators: [validateString]
            },
            trackingId: {
                validators: [validateTrackingId],
                application: parameterApplications.HEADER,
                defaultValue: uuid
            },
            boundingBox: {
                validators: [validateBoundingBox],
                converters: [convertBoundingBox],
                application: parameterApplications.PATH,
                cast: (boundingBox, requestOptions) => {
                    requestOptions.minLon = boundingBox.minLon;
                    requestOptions.maxLon = boundingBox.maxLon;
                    requestOptions.minLat = boundingBox.minLat;
                    requestOptions.maxLat = boundingBox.maxLat;
                }
            },
            zoom: {
                validators: [validateZoomLevel],
                converters: [convertInteger],
                application: parameterApplications.PATH
            },
            x: {
                validators: [validateInteger],
                converters: [convertInteger],
                application: parameterApplications.PATH
            },
            y: {
                validators: [validateInteger],
                converters: [convertInteger],
                application: parameterApplications.PATH
            },
            protocol: {
                validators: [validateOneOfValue(['http', 'https'], 'protocol')]
            },
            sessionId: {
                validators: [validateString]
            }
        };
    }
};
